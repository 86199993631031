body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


$tint_0: #F69D90;
$tint_1: #F7AB9F;
$tint_2: #F8B9AF;
$tint_3: #F9C7BF;
$tint_4: #FBD5CF;
$tint_5: #FCE3DF;
$tint_6: #FDF1EF;

$shade_0: #F69D90;
$shade_1: #D2867B;
$shade_2: #AF7066;
$shade_3: #8C5952;
$shade_4: #69433D;
$shade_5: #462C29;
$shade_6: #231614;


#footer-container {
  width: 100%;
  min-height: 250px;
  background-color: $shade_5;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}