@import '../index.scss';


.home-page-container {

  .body-container {
    width: 100%;
    height: 50vh;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    position: relative !important;

    &--description {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      top: 75%;
      transform: translate(-50%, -50%);
      left: 50%;


      .more-info {
        width: 50%;
      }
    }
    img {
      width: 100%;
      height: auto;

      @media screen and(min-width: 650px) {
        display: none;

      }
    }
  }

  // .body-container {
  //   width: 100%;
  //   height: auto;
  //   background-image: url("https://storage.googleapis.com/dlink-project/Untitled_Artwork.PNG");
  //   background-repeat: no-repeat, repeat;
  //   background-color: red;
  // }

  h1,h2,h3,h4,h5,h6,p,label,span,button, svg {
    color: $tint_0 !important
  }
  img {
    width: 100% !important;
    height: auto;
    animation: fading 2s;
    // &.fading {
      // animation: fading 10s infinite !important;
    // }
  }

  button {
    margin-top: 15px;
    width: 100%;
    border-color: $tint_1 !important;
  }

}
video {
  width: 100% !important;
  background-color: red !important;
}
@keyframes fading {
  0% { opacity: 0.2; }
  100% { opacity: 1; }
}
